import React from 'react'
import { PostReferenceComponent } from "model"
import Markdown from "react-markdown"
import './References.scss'
import rehypeRaw from 'rehype-raw'

interface IReferences {
    references: PostReferenceComponent[] | undefined
}

const References: React.FC<IReferences> = (props) => {
    const { references } = props

    if (!references) return null

    return (
        <div className={"references"}>
            <div className="references__left-column">
                <ol>
                    {references.map((reference) => {
                        const text = reference.text as string
                        const id = reference.id
                        return (
                            <li key={id}>
                                <Markdown
                                    rehypePlugins={[rehypeRaw]}    
                                    className={"references__text"}
                                >
                                    {text}
                                </Markdown>
                            </li>
                        )
                    })}
                </ol>
            </div>
            <div className="references__right-column"></div>
        </div>
    )
}

export default References
/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * DOCUMENTATION
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AboutPageLocalizationRequest,
  AboutPageLocalizationResponse,
  AboutPageRequest,
  AboutPageResponse,
  Error,
  GetAboutPageParams
} from '../../model'
import { strapiAxios } from '../../api/index';



export const getAboutPage = (
    params?: GetAboutPageParams,
 signal?: AbortSignal
) => {
      
      
      return strapiAxios<AboutPageResponse>(
      {url: `/api/about-page`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetAboutPageQueryKey = (params?: GetAboutPageParams,) => {
    return [`/api/about-page`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAboutPageQueryOptions = <TData = Awaited<ReturnType<typeof getAboutPage>>, TError = Error>(params?: GetAboutPageParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAboutPage>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAboutPageQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAboutPage>>> = ({ signal }) => getAboutPage(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAboutPage>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAboutPageQueryResult = NonNullable<Awaited<ReturnType<typeof getAboutPage>>>
export type GetAboutPageQueryError = Error

export const useGetAboutPage = <TData = Awaited<ReturnType<typeof getAboutPage>>, TError = Error>(
 params?: GetAboutPageParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAboutPage>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAboutPageQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const putAboutPage = (
    aboutPageRequest: AboutPageRequest,
 ) => {
      
      
      return strapiAxios<AboutPageResponse>(
      {url: `/api/about-page`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: aboutPageRequest
    },
      );
    }
  


export const getPutAboutPageMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putAboutPage>>, TError,{data: AboutPageRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putAboutPage>>, TError,{data: AboutPageRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putAboutPage>>, {data: AboutPageRequest}> = (props) => {
          const {data} = props ?? {};

          return  putAboutPage(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutAboutPageMutationResult = NonNullable<Awaited<ReturnType<typeof putAboutPage>>>
    export type PutAboutPageMutationBody = AboutPageRequest
    export type PutAboutPageMutationError = Error

    export const usePutAboutPage = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putAboutPage>>, TError,{data: AboutPageRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putAboutPage>>,
        TError,
        {data: AboutPageRequest},
        TContext
      > => {

      const mutationOptions = getPutAboutPageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteAboutPage = (
    
 ) => {
      
      
      return strapiAxios<number>(
      {url: `/api/about-page`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteAboutPageMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAboutPage>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteAboutPage>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAboutPage>>, void> = () => {
          

          return  deleteAboutPage()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteAboutPageMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAboutPage>>>
    
    export type DeleteAboutPageMutationError = Error

    export const useDeleteAboutPage = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAboutPage>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteAboutPage>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDeleteAboutPageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const postAboutPageLocalizations = (
    aboutPageLocalizationRequest: AboutPageLocalizationRequest,
 ) => {
      
      
      return strapiAxios<AboutPageLocalizationResponse>(
      {url: `/api/about-page/localizations`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: aboutPageLocalizationRequest
    },
      );
    }
  


export const getPostAboutPageLocalizationsMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAboutPageLocalizations>>, TError,{data: AboutPageLocalizationRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postAboutPageLocalizations>>, TError,{data: AboutPageLocalizationRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAboutPageLocalizations>>, {data: AboutPageLocalizationRequest}> = (props) => {
          const {data} = props ?? {};

          return  postAboutPageLocalizations(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostAboutPageLocalizationsMutationResult = NonNullable<Awaited<ReturnType<typeof postAboutPageLocalizations>>>
    export type PostAboutPageLocalizationsMutationBody = AboutPageLocalizationRequest
    export type PostAboutPageLocalizationsMutationError = Error

    export const usePostAboutPageLocalizations = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAboutPageLocalizations>>, TError,{data: AboutPageLocalizationRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postAboutPageLocalizations>>,
        TError,
        {data: AboutPageLocalizationRequest},
        TContext
      > => {

      const mutationOptions = getPostAboutPageLocalizationsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * DOCUMENTATION
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CountryListResponse,
  CountryRequest,
  CountryResponse,
  Error,
  GetCountriesParams
} from '../../model'
import { strapiAxios } from '../../api/index';



export const getCountries = (
    params?: GetCountriesParams,
 signal?: AbortSignal
) => {
      
      
      return strapiAxios<CountryListResponse>(
      {url: `/api/countries`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetCountriesQueryKey = (params?: GetCountriesParams,) => {
    return [`/api/countries`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCountriesQueryOptions = <TData = Awaited<ReturnType<typeof getCountries>>, TError = Error>(params?: GetCountriesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCountries>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCountriesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCountries>>> = ({ signal }) => getCountries(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCountries>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCountriesQueryResult = NonNullable<Awaited<ReturnType<typeof getCountries>>>
export type GetCountriesQueryError = Error

export const useGetCountries = <TData = Awaited<ReturnType<typeof getCountries>>, TError = Error>(
 params?: GetCountriesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCountries>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCountriesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postCountries = (
    countryRequest: CountryRequest,
 ) => {
      
      
      return strapiAxios<CountryResponse>(
      {url: `/api/countries`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: countryRequest
    },
      );
    }
  


export const getPostCountriesMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCountries>>, TError,{data: CountryRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postCountries>>, TError,{data: CountryRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCountries>>, {data: CountryRequest}> = (props) => {
          const {data} = props ?? {};

          return  postCountries(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostCountriesMutationResult = NonNullable<Awaited<ReturnType<typeof postCountries>>>
    export type PostCountriesMutationBody = CountryRequest
    export type PostCountriesMutationError = Error

    export const usePostCountries = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCountries>>, TError,{data: CountryRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postCountries>>,
        TError,
        {data: CountryRequest},
        TContext
      > => {

      const mutationOptions = getPostCountriesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getCountriesId = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return strapiAxios<CountryResponse>(
      {url: `/api/countries/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetCountriesIdQueryKey = (id: number,) => {
    return [`/api/countries/${id}`] as const;
    }

    
export const getGetCountriesIdQueryOptions = <TData = Awaited<ReturnType<typeof getCountriesId>>, TError = Error>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCountriesId>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCountriesIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCountriesId>>> = ({ signal }) => getCountriesId(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCountriesId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCountriesIdQueryResult = NonNullable<Awaited<ReturnType<typeof getCountriesId>>>
export type GetCountriesIdQueryError = Error

export const useGetCountriesId = <TData = Awaited<ReturnType<typeof getCountriesId>>, TError = Error>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCountriesId>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCountriesIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const putCountriesId = (
    id: number,
    countryRequest: CountryRequest,
 ) => {
      
      
      return strapiAxios<CountryResponse>(
      {url: `/api/countries/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: countryRequest
    },
      );
    }
  


export const getPutCountriesIdMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCountriesId>>, TError,{id: number;data: CountryRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putCountriesId>>, TError,{id: number;data: CountryRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putCountriesId>>, {id: number;data: CountryRequest}> = (props) => {
          const {id,data} = props ?? {};

          return  putCountriesId(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutCountriesIdMutationResult = NonNullable<Awaited<ReturnType<typeof putCountriesId>>>
    export type PutCountriesIdMutationBody = CountryRequest
    export type PutCountriesIdMutationError = Error

    export const usePutCountriesId = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCountriesId>>, TError,{id: number;data: CountryRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putCountriesId>>,
        TError,
        {id: number;data: CountryRequest},
        TContext
      > => {

      const mutationOptions = getPutCountriesIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteCountriesId = (
    id: number,
 ) => {
      
      
      return strapiAxios<number>(
      {url: `/api/countries/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteCountriesIdMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCountriesId>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteCountriesId>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCountriesId>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteCountriesId(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteCountriesIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCountriesId>>>
    
    export type DeleteCountriesIdMutationError = Error

    export const useDeleteCountriesId = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCountriesId>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteCountriesId>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getDeleteCountriesIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
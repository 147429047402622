import React from 'react'
import './About.scss'
import PageHeader from "components/PageHeader/PageHeader"
import AboutImage from "assets/about.png"
import Markdown from "react-markdown"
import { useGetAboutPage } from "generated/about-page/about-page"
import { useTranslation } from 'react-i18next'
import rehypeRaw from 'rehype-raw'

const About = () => {
    const { t, i18n } = useTranslation()
    const { data } = useGetAboutPage({
        locale: i18n.language.split('-')[0]
    })

    return (
        <div className={"about"}>
            <img src={AboutImage} className={"about__img"}/>
            <PageHeader title={t("aboutPageTitle")}/>
            <Markdown rehypePlugins={[rehypeRaw]}>
                {/*@ts-ignore*/}
                {data?.data?.data?.attributes?.text}
            </Markdown>
            <div className={"fontSize-m"}>

            </div>
        </div>
    )
}

export default About
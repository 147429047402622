import React from 'react'
import Socials from "components/Socials/Socials"
import Button from "components/Button/Button"
import './Support.scss'
import DollarWhite from 'assets/icons/dollar-white.svg'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'

const Support = () => {
    const { t } = useTranslation()
    return (
        <div className={"support"}>
            <div>
                <Link to={"/"} className={"fontSize-xl"}>TIBICEN</Link>
                <div className={"fontSize-m"}>{t('supportUs')}</div>
                <Socials direction={"row"}/>
            </div>
            <div>
                <Link
                    to={"https://linktr.ee/world.music.geek"}
                    target={"_blank"}
                >
                    <Button
                        icon={{
                            name: "dollar-white",
                            position: "left"
                        }}
                    >
                        {t("buttonSupport")}
                    </Button>
                </Link>
            </div>
        </div>
    )
}
export default Support
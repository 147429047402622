import Spotify from 'assets/icons/spotify.svg'
import AppleMusic from 'assets/icons/apple-music.svg'
import Bandcamp from 'assets/icons/bandcamp.svg'
import SoundCloud from 'assets/icons/soundcloud.svg'
import Youtube from 'assets/icons/youtube.svg'
import VK from 'assets/icons/vk-music.png'
import Telegram from 'assets/icons/telegram-music.png'

export const streamings: Record<string, { name: string, icon: string }> = {
    applemusic: {
        name: "Apple Music",
        icon: AppleMusic
    },
    spotify: {
        name: "Spotify",
        icon: Spotify
    },
    bandcamp: {
        name: "Bandcamp",
        icon: Bandcamp
    },
    youtube: {
        name: "Youtube",
        icon: Youtube
    },
    soundcloud: {
        name: "SoundCloud",
        icon: SoundCloud
    },
    telegram: {
        name: "Telegram",
        icon: Telegram
    },
    boom: {
        name: "VK Music",
        icon: VK
    }
}

import { useEffect, useState } from "react"
import { Album } from "model"
import { getImageUrl } from "utils"
import { TTag, TTagName } from "types"
import path from "path"

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<{
        width: number,
        height: number
    }>({
        width: 0,
        height: 0,
    })

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }

        window.addEventListener("resize", handleResize)
        handleResize()
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return windowSize
}

export const useGetAlbumData = (album: Album | undefined) => {
    const name = album?.name

    const imageLink = getImageUrl(album?.cover?.data?.attributes?.url)

    // Джойним всех артистов
    const artist = album?.artists?.data?.map((artist) => artist?.attributes?.name).join(", ")

    const year = album?.year
    const labels = album?.labels?.data?.[0]?.attributes?.name

    // tags
    const genres = album?.genres?.data
    const countries = album?.countries?.data
    const instruments = album?.instruments?.data
    const nationalities = album?.nationalities?.data

    const tags: Record<TTagName, TTag[] | undefined> = {
        genres: genres,
        countries: countries,
        instruments: instruments,
        nationalities: nationalities,
    }
    const streamings = album?.streamings

    return { name, imageLink, artist, tags, streamings, year, labels }
}

// export const useGetIcon = (iconName: string) => {
    // return require(`${path.dirname}${iconName}.svg`)
// }
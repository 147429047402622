import React from 'react'
import './Icon.scss'
import { IIcon } from 'types'
import { getStyles } from 'helpers'

const Icon: React.FC<IIcon> = (props) => {
    const { name, position } = props

    const styles = getStyles("icon", [position])

    return (
        <img
            src={require(`assets/icons/${name}.svg`)}
            alt=""
            className={styles}
        />
    )
}

export default Icon
import React from 'react'
import './Loader.scss'
import { useTranslation } from 'react-i18next'

const Loader = () => {
    const { t } = useTranslation()

    return (
        <div className="loader">{t("loadingMessage")}</div>
    )
}

export default Loader
/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * DOCUMENTATION
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Error,
  GetNationalitiesParams,
  NationalityListResponse,
  NationalityRequest,
  NationalityResponse
} from '../../model'
import { strapiAxios } from '../../api/index';



export const getNationalities = (
    params?: GetNationalitiesParams,
 signal?: AbortSignal
) => {
      
      
      return strapiAxios<NationalityListResponse>(
      {url: `/api/nationalities`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetNationalitiesQueryKey = (params?: GetNationalitiesParams,) => {
    return [`/api/nationalities`, ...(params ? [params]: [])] as const;
    }

    
export const getGetNationalitiesQueryOptions = <TData = Awaited<ReturnType<typeof getNationalities>>, TError = Error>(params?: GetNationalitiesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNationalities>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetNationalitiesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getNationalities>>> = ({ signal }) => getNationalities(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getNationalities>>, TError, TData> & { queryKey: QueryKey }
}

export type GetNationalitiesQueryResult = NonNullable<Awaited<ReturnType<typeof getNationalities>>>
export type GetNationalitiesQueryError = Error

export const useGetNationalities = <TData = Awaited<ReturnType<typeof getNationalities>>, TError = Error>(
 params?: GetNationalitiesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNationalities>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetNationalitiesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postNationalities = (
    nationalityRequest: NationalityRequest,
 ) => {
      
      
      return strapiAxios<NationalityResponse>(
      {url: `/api/nationalities`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: nationalityRequest
    },
      );
    }
  


export const getPostNationalitiesMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNationalities>>, TError,{data: NationalityRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postNationalities>>, TError,{data: NationalityRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNationalities>>, {data: NationalityRequest}> = (props) => {
          const {data} = props ?? {};

          return  postNationalities(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostNationalitiesMutationResult = NonNullable<Awaited<ReturnType<typeof postNationalities>>>
    export type PostNationalitiesMutationBody = NationalityRequest
    export type PostNationalitiesMutationError = Error

    export const usePostNationalities = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNationalities>>, TError,{data: NationalityRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postNationalities>>,
        TError,
        {data: NationalityRequest},
        TContext
      > => {

      const mutationOptions = getPostNationalitiesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getNationalitiesId = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return strapiAxios<NationalityResponse>(
      {url: `/api/nationalities/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetNationalitiesIdQueryKey = (id: number,) => {
    return [`/api/nationalities/${id}`] as const;
    }

    
export const getGetNationalitiesIdQueryOptions = <TData = Awaited<ReturnType<typeof getNationalitiesId>>, TError = Error>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNationalitiesId>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetNationalitiesIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getNationalitiesId>>> = ({ signal }) => getNationalitiesId(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getNationalitiesId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetNationalitiesIdQueryResult = NonNullable<Awaited<ReturnType<typeof getNationalitiesId>>>
export type GetNationalitiesIdQueryError = Error

export const useGetNationalitiesId = <TData = Awaited<ReturnType<typeof getNationalitiesId>>, TError = Error>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNationalitiesId>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetNationalitiesIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const putNationalitiesId = (
    id: number,
    nationalityRequest: NationalityRequest,
 ) => {
      
      
      return strapiAxios<NationalityResponse>(
      {url: `/api/nationalities/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: nationalityRequest
    },
      );
    }
  


export const getPutNationalitiesIdMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putNationalitiesId>>, TError,{id: number;data: NationalityRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putNationalitiesId>>, TError,{id: number;data: NationalityRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putNationalitiesId>>, {id: number;data: NationalityRequest}> = (props) => {
          const {id,data} = props ?? {};

          return  putNationalitiesId(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutNationalitiesIdMutationResult = NonNullable<Awaited<ReturnType<typeof putNationalitiesId>>>
    export type PutNationalitiesIdMutationBody = NationalityRequest
    export type PutNationalitiesIdMutationError = Error

    export const usePutNationalitiesId = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putNationalitiesId>>, TError,{id: number;data: NationalityRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putNationalitiesId>>,
        TError,
        {id: number;data: NationalityRequest},
        TContext
      > => {

      const mutationOptions = getPutNationalitiesIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteNationalitiesId = (
    id: number,
 ) => {
      
      
      return strapiAxios<number>(
      {url: `/api/nationalities/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteNationalitiesIdMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteNationalitiesId>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteNationalitiesId>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteNationalitiesId>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteNationalitiesId(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteNationalitiesIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteNationalitiesId>>>
    
    export type DeleteNationalitiesIdMutationError = Error

    export const useDeleteNationalitiesId = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteNationalitiesId>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteNationalitiesId>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getDeleteNationalitiesIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
import React from 'react'
import './NavLink.scss'
import { NavLinkProps, NavLink as RouterLink } from "react-router-dom"
import { useTranslation } from 'react-i18next'

interface INavLink extends NavLinkProps {
    isBurger?: boolean
}

const NavLink: React.FC<INavLink> = (props) => {
    const { t } = useTranslation()

    return (
        <RouterLink
            {...props}
            className={({ isActive, isPending }) => {
                const css = ["nav-link"]
                if (isActive) {
                    css.push("nav-link--active")
                } else {
                    css.push("nav-link--inactive")
                }
                if (props.isBurger) {
                    css.push("nav-link--burger")
                }

                return css.filter(Boolean).join(" ")
            }}
        >
            {t(props.children as string)}
        </RouterLink>
    )
}
export default NavLink
import React from 'react'
import { Outlet } from "react-router-dom"
import './ContentWithFilterLayout.scss'
import FilterList from "components/FilterList/FilterList"

const ContentWithFilterLayout = () => {
    return (
        <div className={"content-with-filter-layout"}>
            <FilterList />
            <Outlet/>
        </div>
    )
}

export default ContentWithFilterLayout
import React from 'react'
import './Contacts.scss'
import PageHeader from "components/PageHeader/PageHeader"
import Socials from "components/Socials/Socials"
import Divider from "components/Divider/Divider"
import Email from 'assets/icons/email.svg'
import { useTranslation } from 'react-i18next'
import { useGetContactPage } from 'generated/contact-page/contact-page'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const Contacts = () => {
    const { t, i18n } = useTranslation()
    const { data } = useGetContactPage({
        locale: i18n.language.split("-")[0]
    })

    return (
        <div className={"contacts"}>
            <div>
                <PageHeader title={t("contactsPageTitle")}/>
                <Divider margin={"1rem"}/>
                <div className={"contacts__heading"}>
                    <div className={"fontSize-xxl"}>
                        <Markdown rehypePlugins={[rehypeRaw]}>
                            {data?.data?.data?.attributes?.heading}
                        </Markdown>
                    </div>
                    <Socials direction={"row"}/>
                </div>
            </div>
            <Divider margin={"1rem"}/>
            <div className={"contacts__footer"}>
                <div className={"contacts__email"}>
                    <img
                        src={Email}
                        alt=""
                    />
                    <span className={"fontSize-m"}>{data?.data?.data?.attributes?.email}</span>
                </div>
                <p className={"contacts__description fontSize-m"}>
                    <Markdown rehypePlugins={[rehypeRaw]}>
                        {data?.data?.data?.attributes?.text}
                    </Markdown>
                </p>
            </div>
        </div>
    )
}

export default Contacts
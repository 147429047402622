import React from 'react'
import './Tag.scss'
import { useNavigate } from "react-router-dom"
import { TTag, TTagName } from "types"

const Tag: React.FC<{
    tag: TTag,
    type: TTagName
}> = (props) => {
    const { tag, type } = props
    const navigate = useNavigate()
    const tagName = tag?.attributes?.name as string

    return (
        <div
            className={"tag"}
            onClick={() => {
                navigate(`/library?${type}=${tagName.toLowerCase()}`)
            }}
        >
            <span className={"fontSize-xs fontWeight-bold"}>
            {`#${tag?.attributes?.name}`}
            </span>
        </div>
    )
}

export default Tag
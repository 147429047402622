import React from 'react'
import { useTranslation } from 'react-i18next'

const EmptyMessage = () => {
    const { t } = useTranslation()
    
    return (
        <div>
            {t('emptyMessage')}
        </div>
    )
}

export default EmptyMessage
import React, { useCallback } from 'react'
import './Post.scss'
import { useGetPosts } from "generated/post/post"
import { useLocation, useParams } from "react-router-dom"
import StreamingList from "components/StreamingList/StreamingList"
import SeeMore from "components/SeeMore/SeeMore"
import Divider from "components/Divider/Divider"
import {
    Album, AlbumCountriesDataItem, AlbumGenresDataItem, AlbumInstrumentsDataItem,
} from "model"
import TagsList from "components/TagsList/TagsList"
import { getFormatDate } from "utils"
import { useGetAlbumData } from "hooks"
import Markdown from "react-markdown"
import References from "components/References/References"
import Authors from "components/Authors/Authors"
import Share from "components/Share/Share"
import { TTagName } from "types"
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { strapiAxios } from 'api'
import qs from 'qs'
import rehypeRaw from 'rehype-raw'

const Post = () => {
    const params = useParams()
    const { t, i18n } = useTranslation()
    const location = useLocation()

    const { data: albumResponse } = useQuery({
        queryKey: ["albums", params.id, location.pathname],
        queryFn: async () => {
            const query = qs.stringify({
                populate: "deep,4",
                filters: {
                    id: {
                        $eq: params.id
                    }
                }
            })

            const response = await strapiAxios.get(`/api/albums?${query}`)
            return response.data
        },
        enabled: !!params.id
    })

    const post = albumResponse?.data[0]?.attributes?.posts?.data?.filter((post: any) => post?.attributes?.locale === i18n.language.split("-")[0])?.[0]?.attributes
    const authors = post?.authors?.data
    const album = albumResponse?.data?.[0]?.attributes

    const {
        name,
        imageLink,
        artist,
        year,
        labels,
        streamings
    } = useGetAlbumData(album)

    const publishedAt = post?.prevPublishedAt ?? post?.publishedAt
    // edited в posts не добавляем, потому что когда этот пост со старыми данными будет обновлен, то обновления не увидим
    const updatedAt = post?.updatedAt


    const spotifyLink = useCallback((link: string | undefined) => {
        if (!link) return ""
        const splittedLink = link.split('/').splice(-2)
        return `https://open.spotify.com/embed/${splittedLink.join("/")}`
    }, [])

    const tags: Record<TTagName, any[] | undefined> = {
        countries: album?.countries as AlbumCountriesDataItem[],
        genres: album?.genres as AlbumGenresDataItem[],
        instruments: album?.instruments as AlbumInstrumentsDataItem[],
        nationalities: album?.nationalities as AlbumInstrumentsDataItem[]
    }

    return (
        <div className={"post"}>
            <div className={"post__album"}>
                <img
                    className={"post__cover"}
                    src={imageLink}
                    alt=""
                />
                <div className={"post__information flex--column gap--s jcc"}>
                    <span className={"fontSize-l fontWeight-bold"}>{artist}</span>
                    <span className={"fontSize-xxl"}>{name}</span>
                    <span className={"fontSize-m"}>{`${labels}, ${year}`}</span>
                </div>
                <div className={"post__authors"}>
                    <Divider margin={"1rem"}/>
                    <div className={"post__author"}>
                        <Authors authors={authors} publishedAt={getFormatDate(publishedAt)}/>
                        <Share/>
                    </div>
                </div>
            </div>
            <Divider margin={"1rem"}/>
            <div className={"post__text flex--column gap--xl"}>
                <Markdown
                    rehypePlugins={[rehypeRaw]}
                    className={"markdown"}
                >
                    {post?.text}</Markdown>
                <div>
                    <span className={"fontSize-xs post__date"}>{t('postPublish')} {getFormatDate(publishedAt)}</span>
                    <br/>
                    <span
                        className={"fontSize-xs post__date"}>({t("postUpdated")} {getFormatDate(updatedAt)})</span>
                </div>
            </div>
            <Divider margin={"1rem"}/>
            <div className={"flex--column gap--xl"}>
                <div className={"fontSize-xs"}>
                    {t("streamingDisclamer")}
                </div>
                <div className={`${streamings?.spotify ? "post__streamings" : ""}`}>
                    {streamings?.spotify ?
                        <iframe
                            style={{ borderRadius: "12px" }}
                            src={spotifyLink(streamings?.spotify)}
                            width="100%"
                            height="352"
                            title={"Spotify"}
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                            loading="lazy"
                            frameBorder={"0"}
                        ></iframe>
                        : null
                    }
                    <StreamingList {...streamings} />
                </div>
            </div>
            <Divider margin={"1rem"}/>
            {
                Object.keys(tags)?.length ?
                    <TagsList tags={tags}/>
                    : null
            }
            {post?.references?.length ?
                <>
                    <Divider margin={"1rem"}/>
                    <References references={post?.references}/>
                </>
                : null
            }
            <Divider margin={"1rem"}/>
            <SeeMore album={album} id={params.id}/>
        </div>
    )
}

export default Post
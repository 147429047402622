import React from 'react'
import './Footer.scss'
import Support from "components/Support/Support"

const Footer = () => {
    const currentYear = new Date().getFullYear()

    return (
        <footer className={"footer"}>
            <Support/>
            <span className={"fontSize-xs footer__copyright"}>
            tibicen ©{currentYear}
            </span>
        </footer>
    )
}

export default Footer
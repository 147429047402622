import React from 'react'
import { PersonAvatar } from "model"
import { getImageUrl } from "utils"
import './Avatar.scss'

const Avatar: React.FC<PersonAvatar> = (props) => {
    const { data } = props
    if (!data) return null

    const { attributes } = data

    if (!attributes) return null

    const { url, name } = attributes

    return (
        <div className={"avatar"}>
            <img src={getImageUrl(url)} alt={name}/>
        </div>
    )
}

export default Avatar
/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * DOCUMENTATION
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Error,
  GenreListResponse,
  GenreRequest,
  GenreResponse,
  GetGenresParams
} from '../../model'
import { strapiAxios } from '../../api/index';



export const getGenres = (
    params?: GetGenresParams,
 signal?: AbortSignal
) => {
      
      
      return strapiAxios<GenreListResponse>(
      {url: `/api/genres`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetGenresQueryKey = (params?: GetGenresParams,) => {
    return [`/api/genres`, ...(params ? [params]: [])] as const;
    }

    
export const getGetGenresQueryOptions = <TData = Awaited<ReturnType<typeof getGenres>>, TError = Error>(params?: GetGenresParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGenres>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGenresQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGenres>>> = ({ signal }) => getGenres(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGenres>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGenresQueryResult = NonNullable<Awaited<ReturnType<typeof getGenres>>>
export type GetGenresQueryError = Error

export const useGetGenres = <TData = Awaited<ReturnType<typeof getGenres>>, TError = Error>(
 params?: GetGenresParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGenres>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetGenresQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postGenres = (
    genreRequest: GenreRequest,
 ) => {
      
      
      return strapiAxios<GenreResponse>(
      {url: `/api/genres`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: genreRequest
    },
      );
    }
  


export const getPostGenresMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postGenres>>, TError,{data: GenreRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postGenres>>, TError,{data: GenreRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postGenres>>, {data: GenreRequest}> = (props) => {
          const {data} = props ?? {};

          return  postGenres(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostGenresMutationResult = NonNullable<Awaited<ReturnType<typeof postGenres>>>
    export type PostGenresMutationBody = GenreRequest
    export type PostGenresMutationError = Error

    export const usePostGenres = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postGenres>>, TError,{data: GenreRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postGenres>>,
        TError,
        {data: GenreRequest},
        TContext
      > => {

      const mutationOptions = getPostGenresMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getGenresId = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return strapiAxios<GenreResponse>(
      {url: `/api/genres/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetGenresIdQueryKey = (id: number,) => {
    return [`/api/genres/${id}`] as const;
    }

    
export const getGetGenresIdQueryOptions = <TData = Awaited<ReturnType<typeof getGenresId>>, TError = Error>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGenresId>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGenresIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGenresId>>> = ({ signal }) => getGenresId(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGenresId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGenresIdQueryResult = NonNullable<Awaited<ReturnType<typeof getGenresId>>>
export type GetGenresIdQueryError = Error

export const useGetGenresId = <TData = Awaited<ReturnType<typeof getGenresId>>, TError = Error>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGenresId>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetGenresIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const putGenresId = (
    id: number,
    genreRequest: GenreRequest,
 ) => {
      
      
      return strapiAxios<GenreResponse>(
      {url: `/api/genres/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: genreRequest
    },
      );
    }
  


export const getPutGenresIdMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putGenresId>>, TError,{id: number;data: GenreRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putGenresId>>, TError,{id: number;data: GenreRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putGenresId>>, {id: number;data: GenreRequest}> = (props) => {
          const {id,data} = props ?? {};

          return  putGenresId(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutGenresIdMutationResult = NonNullable<Awaited<ReturnType<typeof putGenresId>>>
    export type PutGenresIdMutationBody = GenreRequest
    export type PutGenresIdMutationError = Error

    export const usePutGenresId = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putGenresId>>, TError,{id: number;data: GenreRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putGenresId>>,
        TError,
        {id: number;data: GenreRequest},
        TContext
      > => {

      const mutationOptions = getPutGenresIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteGenresId = (
    id: number,
 ) => {
      
      
      return strapiAxios<number>(
      {url: `/api/genres/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteGenresIdMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteGenresId>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteGenresId>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteGenresId>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteGenresId(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteGenresIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteGenresId>>>
    
    export type DeleteGenresIdMutationError = Error

    export const useDeleteGenresId = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteGenresId>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteGenresId>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getDeleteGenresIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
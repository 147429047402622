import { Outlet } from "react-router-dom"
import './Layout.scss'
import Container from "components/Container/Container"
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'

const Layout = () => {

    return (
        <Container>
            <Header/>
            <main className={"main"}>
                <Outlet/>
            </main>
            <Footer/>
        </Container>
    )
}

export default Layout
export const setCountryLinks = (map: any) => {
    const backendURL: string = window.location.host.match(/localhost:3000/gi) ? "http://localhost:3000" : "https://tibicen.cc"

    const obj: Record<string, string> = {}
    Object.entries(map).map(([key, value]: any) => {
        if (!value.albums) {
            obj[key] = value
        } else {
            obj[key] = {
                ...value,
                link: `${backendURL}/#/library?countries=${map[key].alias.toLowerCase()}`
            }
        }
    })

    return obj
}
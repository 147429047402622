import React, { useContext, useEffect, useState } from 'react'
import './FilterList.scss'
import Filter from "components/Filter/Filter"
import { Form, Formik, FormikValues } from "formik"
import Button from "components/Button/Button"
import { useLocation, useNavigate } from "react-router-dom"
import { useGetFilter } from "generated/filter/filter"
import { useTranslation } from 'react-i18next'

const FilterList = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [values, setValues] = useState<FormikValues>({})
    const [current, setCurrent] = useState<number>(-1)
    const { t } = useTranslation()

    useEffect(() => {
        const search = location.search
        if (!search) {
            setValues({})
            return
        }
        const queryWithoutQuestionMark = search.slice(1)
        const separateQueries = queryWithoutQuestionMark.split("&")
        const obj: Record<string, string[]> = {}
        const getValuesFromSeparateQueries = (query: any) => {
            const [criteria, queries] = query.split("=")
            const cleanedArrayFromSymbols = queries.split("%26")
                .map((query: string) => decodeURI(query).replace("%20", " "))
            return [criteria, cleanedArrayFromSymbols]
        }
        separateQueries.forEach((query) => {
            const [criteria, array] = getValuesFromSeparateQueries(query)
            obj[criteria] = array
        })
        setValues(obj)
    }, [location])

    const { data } = useGetFilter(
        {
            "pagination[pageSize]": 200
        },
        {
            query: {
                queryKey: [],
                refetchOnWindowFocus: false,
                refetchOnMount: false
            }
        }
    )

    const filters = data?.data?.data?.attributes?.filters as string[]

    const handleSubmit = (values: FormikValues) => {
        const query = new URLSearchParams()
        const filteredObject: Record<string, string[]> = {}
        Object.entries(values).forEach(([key, values]) => {
            if (!Array.isArray(values)) {
                throw new TypeError(`${key} is not an Array`)
            }
            if (values.length) {
                filteredObject[key] = values
                query.append(key, encodeURI(values.join("&")))
            }
        })
        navigate(`?${query.toString()}`)
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={values}
            enableReinitialize={true}
        >
            {({ values }) => {
                return (
                    <Form>
                        <div className={"filter-list"}>
                            {filters?.map((filter, index) => {
                                return (
                                    <Filter
                                        name={filter}
                                        key={`${filter}-${index}`}
                                        setCurrent={() => {
                                            if (current === index) {
                                                setCurrent(-1)
                                            } else {
                                                setCurrent(index)
                                            }
                                        }
                                        }
                                        isCurrent={current === index}
                                    />
                                )
                            })}
                            <div
                                className={"filter-list__actions"}
                            >
                                <Button
                                    type={"submit"}
                                    variant={"primary"}
                                >
                                    {t("buttonSearch")}
                                </Button>
                                <Button
                                    type={"reset"}
                                    variant={"secondary"}
                                    onClick={() => {
                                        navigate({
                                            pathname: location.pathname,
                                            search: ''
                                        })
                                    }}
                                >
                                    {t("buttonClear")}
                                </Button>
                            </div>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default FilterList
import { LinkProps } from "react-router-dom"

export const navigation: LinkProps[] = [
    {
        to: "/library",
        children: "navigationLibrary"
    },
    {
        to: "/blog",
        children: "navigationBlog"
    },
    {
        to: "/about",
        children: "navigationAbout"
    },
    {
        to: "/people",
        children: "navigationPeople"
    },
    {
        to: "/contacts",
        children: "navigationContacts"
    }
]
export const internazionalization =
    {
        resources: {
            en: {
                translation: {
                    libraryPageTitle: "Library",
                    blogPageTitle: "Blog",
                    aboutPageTitle: "About",
                    peoplePageTitle: "People",
                    contactsPageTitle: "Contacts",

                    navigationLibrary: "Library",
                    navigationBlog: "Blog",
                    navigationPeople: "People",
                    navigationAbout: "About",
                    navigationContacts: "Contacts",

                    buttonDownloadMore: "Download more",
                    buttonSearch: "Search",
                    buttonClear: "Clear",
                    buttonSupport: "Support us",
                    buttonHideMap: "Hide map",
                    buttonShowMap: "Show map",

                    postPublish: "Publish at",
                    postUpdated: "Updated at",

                    streamingDisclamer: "If your Spotify widget is not displayed correctly, try turning on the VPN",

                    emptyMessage: "No matches found. Try changing filters.",
                    loadingMessage: "Loading...",

                    seeMoreFound: "Listen more from {{tag}}",
                    seeMoreNotFound: "No recommendations found for {{tag}}, try clicking on any tag above!",

                    share: "Share:",

                    listenIn: "Listen in:",

                    supportUs: "Subscribe to us or support the work of the project",

                    tags: "Tags",

                    albumName: "Albums:",
                    albumsNotFound: "There are no albums yet",

                    genres: "Genres",
                    countries: "Countries",
                    instruments: "Instruments",
                    nationalities: "Nations",
                }
            },
            ru: {
                translation: {
                    libraryPageTitle: "Медиатека",
                    blogPageTitle: "Блог",
                    aboutPageTitle: "О нас",
                    peoplePageTitle: "Команда",
                    contactsPageTitle: "Контакты",

                    navigationLibrary: "Медиатека",
                    navigationBlog: "Блог",
                    navigationPeople: "Команда",
                    navigationAbout: "О нас",
                    navigationContacts: "Контакты",

                    buttonDownloadMore: "Загрузить ещё",
                    buttonSearch: "Искать",
                    buttonClear: "Очистить",
                    buttonSupport: "Поддержать",
                    buttonHideMap: "Скрыть карту",
                    buttonShowMap: "Показать карту",

                    postPublish: "Опубликовано",
                    postUpdated: "Отредактировано",

                    streamingDisclamer: "Если у вас некорректно отображается виджет Spotify, то попробуйте включить VPM",

                    emptyMessage: "Совпадений не найдено. Попробуйте поменять фильтры.",
                    loadingMessage: "Загрузка...",

                    seeMoreFound: "Послушать ещё из {{tag}}",
                    seeMoreNotFound: "Не найдено рекомендаций по {{tag}}, попробуйте нажать на любой тег выше!",

                    share: "Поделиться:",

                    listenIn: "Слушать в:",

                    supportUs: "Подпишитесь на нас или поддержите работу проекта",

                    tags: "Теги",

                    albumName: "Альбомы:",
                    albumsNotFound: "Пока нет альбомов",

                    genres: "Стили",
                    countries: "Страны",
                    instruments: "Инструменты",
                    nationalities: "Народы",
                }
            },
        },
        fallbackLng: "ru-RU",
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    }
import React from 'react'
import Divider from "components/Divider/Divider"
import './PageHeader.scss'

interface IPageHeader extends React.HTMLAttributes<HTMLDivElement> {
    title: string
    right?: React.ReactNode
}

const PageHeader: React.FC<IPageHeader> = (props) => {
    const { title, children, right } = props

    return (
        <header className={"page-header"}>
            <div className={"page-header__title"}>{title}</div>
            <div className={"page-header__right"}>{right}</div>
        </header>
    )
}

export default PageHeader
import React from 'react'
import './Navigation.scss'
import NavLink from "components/NavLink/NavLink"
import { navigation } from "router"

const Navigation = () => {

    return (
        <div className={"navigation"}>
            {navigation.map((item, index) => {
                return (
                    <NavLink
                        {...item}
                        key={index}
                    />
                )
            })}
        </div>
    )
}

export default Navigation
import React from 'react'
import './TagsList.scss'
import { TTagName } from "types"
import Tag from "components/Tag/Tag"
import { useTranslation } from 'react-i18next'

interface ITagsList extends React.HTMLAttributes<HTMLDivElement> {
    tags: Record<TTagName, any>
    hideTitle?: boolean
    isPreview?: boolean
}

const TagsList: React.FC<ITagsList> = (props) => {
    const { tags, hideTitle, isPreview } = props
    const { t } = useTranslation()

    if (isPreview) {

        return (
            <div className={"tags__list"}>
                {tags.countries.data[0]
                    &&
                    <Tag
                        tag={tags.countries.data[0]}
                        type={"countries"}
                    />
                }
                {
                    tags.genres.data[0] &&
                    <Tag
                        tag={tags.genres.data[0]}
                        type={"genres"}
                    />
                }
                {
                    tags.instruments.data[0] ?
                        <Tag
                            tag={tags.instruments.data[0]}
                            type={"instruments"}
                        />
                        :
                        tags.genres.data[1] &&
                        <Tag
                            tag={tags.genres.data[1]}
                            type={"genres"}
                        />
                }
                {tags.nationalities.data[0]
                    ?
                    <Tag
                        tag={tags.nationalities.data[0]}
                        type={"nationalities"}
                    />
                    :
                    tags.genres.data[2] &&
                    <Tag
                        tag={tags.genres.data[2]}
                        type={"genres"}
                    />
                }
            </div>
        )
    } else {
        return (
            <div className={"tags"}>
                {
                    hideTitle ?
                        null :
                        <div className={"fontSize-m fontWeight-bold"}>{t("tags")}</div>
                }
                <div className={"tags__list"}>
                    {
                        Object.entries(tags).map((entries) => {
                            const type = entries[0] as TTagName
                            const tags = entries[1] as any

                            return tags?.data?.map((tag: any, index: number) => {
                                return (
                                    <Tag
                                        key={index}
                                        tag={tag}
                                        type={type}
                                    />
                                )
                            })
                        })
                    }
                </div>
            </div>
        )
    }
}

export default TagsList
import { useLocation } from "react-router-dom"
import { useEffect } from "react"


const ScrollToTop = () => {
    const location = useLocation()
    if ('scrollRestoration' in window.history) {
        // Back off, browser, I got this...
        window.history.scrollRestoration = 'manual'
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    return null
}

export default ScrollToTop
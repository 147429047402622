/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * DOCUMENTATION
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Error,
  FilterRequest,
  FilterResponse,
  GetFilterParams
} from '../../model'
import { strapiAxios } from '../../api/index';



export const getFilter = (
    params?: GetFilterParams,
 signal?: AbortSignal
) => {
      
      
      return strapiAxios<FilterResponse>(
      {url: `/api/filter`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetFilterQueryKey = (params?: GetFilterParams,) => {
    return [`/api/filter`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFilterQueryOptions = <TData = Awaited<ReturnType<typeof getFilter>>, TError = Error>(params?: GetFilterParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFilter>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFilterQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFilter>>> = ({ signal }) => getFilter(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFilter>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFilterQueryResult = NonNullable<Awaited<ReturnType<typeof getFilter>>>
export type GetFilterQueryError = Error

export const useGetFilter = <TData = Awaited<ReturnType<typeof getFilter>>, TError = Error>(
 params?: GetFilterParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFilter>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFilterQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const putFilter = (
    filterRequest: FilterRequest,
 ) => {
      
      
      return strapiAxios<FilterResponse>(
      {url: `/api/filter`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: filterRequest
    },
      );
    }
  


export const getPutFilterMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFilter>>, TError,{data: FilterRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putFilter>>, TError,{data: FilterRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFilter>>, {data: FilterRequest}> = (props) => {
          const {data} = props ?? {};

          return  putFilter(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFilterMutationResult = NonNullable<Awaited<ReturnType<typeof putFilter>>>
    export type PutFilterMutationBody = FilterRequest
    export type PutFilterMutationError = Error

    export const usePutFilter = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFilter>>, TError,{data: FilterRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putFilter>>,
        TError,
        {data: FilterRequest},
        TContext
      > => {

      const mutationOptions = getPutFilterMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteFilter = (
    
 ) => {
      
      
      return strapiAxios<number>(
      {url: `/api/filter`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteFilterMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFilter>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteFilter>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFilter>>, void> = () => {
          

          return  deleteFilter()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFilterMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFilter>>>
    
    export type DeleteFilterMutationError = Error

    export const useDeleteFilter = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFilter>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFilter>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDeleteFilterMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
import React from 'react'
import './Container.scss'

interface IContainer extends React.HTMLAttributes<HTMLDivElement> {
    variant?: "grey" | "white"
}

const Container: React.FC<IContainer> = (props) => {
    const { children, variant = "grey" } = props

    const css = ["container"]
    css.push(`container--${variant}`)

    return (
        <div className={css.filter(Boolean).join(" ")}>
            {children}
        </div>
    )
}

export default Container
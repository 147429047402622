import React from 'react'
import './Authors.scss'
import { PersonAvatar, PostAuthorsDataItem } from "model"
import Avatar from "components/Avatar/Avatar"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'

interface IAuthors {
    authors: PostAuthorsDataItem[] | undefined
    publishedAt: String | undefined
}

const Authors: React.FC<IAuthors> = (props) => {
    const { authors, publishedAt } = props
    const { t } = useTranslation()

    if (!authors) return null

    return (
        <div className={"flex--column"}>
            {
                authors.map((author, index) => {
                    const data = author.attributes
                    const id = author.id
                    if (!data) return null

                    const name = data.name as string
                    const avatar = data.avatar as PersonAvatar | undefined

                    return (
                        <Link to={"/people"} key={id}>
                            <div className={"author"}>
                                <Avatar data={avatar?.data}/>
                                <div className={"flex--column gap--xs"}>
                                    <div className={"author__name"}>{name}</div>
                                    <div className={"author__publish-date"}>{`${t("postPublish")} ${publishedAt}`}</div>
                                </div>
                            </div>
                        </Link>
                    )
                })
            }
        </div>
    )
}

export default Authors
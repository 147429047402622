import React from 'react'
import './Streaming.scss'
import { streamings } from './constants'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'

interface IStreaming {
    type: string
    link: string
}

const Streaming: React.FC<IStreaming> = (props) => {
    const { type, link } = props
    const { t } = useTranslation()

    return (
        <Link
            className="streaming"
            to={link}
            target={"_blank"}
        >
            <span className={"streaming__name"}>
                <span className={"fontSize-xs"}>{t("listenIn")}</span>
                <span className={"fontSize-m fontWeight-bold"}>{streamings[type].name}</span>
            </span>
            <span className={"streaming__logo"}>
                <img src={streamings[props.type].icon} alt={`${streamings[props.type].name} icon`}/>
            </span>
        </Link>
    )
}

export default Streaming
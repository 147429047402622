/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * DOCUMENTATION
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Error,
  GetInstrumentsParams,
  InstrumentListResponse,
  InstrumentRequest,
  InstrumentResponse
} from '../../model'
import { strapiAxios } from '../../api/index';



export const getInstruments = (
    params?: GetInstrumentsParams,
 signal?: AbortSignal
) => {
      
      
      return strapiAxios<InstrumentListResponse>(
      {url: `/api/instruments`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetInstrumentsQueryKey = (params?: GetInstrumentsParams,) => {
    return [`/api/instruments`, ...(params ? [params]: [])] as const;
    }

    
export const getGetInstrumentsQueryOptions = <TData = Awaited<ReturnType<typeof getInstruments>>, TError = Error>(params?: GetInstrumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInstruments>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInstrumentsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInstruments>>> = ({ signal }) => getInstruments(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInstruments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInstrumentsQueryResult = NonNullable<Awaited<ReturnType<typeof getInstruments>>>
export type GetInstrumentsQueryError = Error

export const useGetInstruments = <TData = Awaited<ReturnType<typeof getInstruments>>, TError = Error>(
 params?: GetInstrumentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInstruments>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInstrumentsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postInstruments = (
    instrumentRequest: InstrumentRequest,
 ) => {
      
      
      return strapiAxios<InstrumentResponse>(
      {url: `/api/instruments`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: instrumentRequest
    },
      );
    }
  


export const getPostInstrumentsMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postInstruments>>, TError,{data: InstrumentRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postInstruments>>, TError,{data: InstrumentRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postInstruments>>, {data: InstrumentRequest}> = (props) => {
          const {data} = props ?? {};

          return  postInstruments(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostInstrumentsMutationResult = NonNullable<Awaited<ReturnType<typeof postInstruments>>>
    export type PostInstrumentsMutationBody = InstrumentRequest
    export type PostInstrumentsMutationError = Error

    export const usePostInstruments = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postInstruments>>, TError,{data: InstrumentRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postInstruments>>,
        TError,
        {data: InstrumentRequest},
        TContext
      > => {

      const mutationOptions = getPostInstrumentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getInstrumentsId = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return strapiAxios<InstrumentResponse>(
      {url: `/api/instruments/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetInstrumentsIdQueryKey = (id: number,) => {
    return [`/api/instruments/${id}`] as const;
    }

    
export const getGetInstrumentsIdQueryOptions = <TData = Awaited<ReturnType<typeof getInstrumentsId>>, TError = Error>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInstrumentsId>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInstrumentsIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInstrumentsId>>> = ({ signal }) => getInstrumentsId(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInstrumentsId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInstrumentsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getInstrumentsId>>>
export type GetInstrumentsIdQueryError = Error

export const useGetInstrumentsId = <TData = Awaited<ReturnType<typeof getInstrumentsId>>, TError = Error>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInstrumentsId>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInstrumentsIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const putInstrumentsId = (
    id: number,
    instrumentRequest: InstrumentRequest,
 ) => {
      
      
      return strapiAxios<InstrumentResponse>(
      {url: `/api/instruments/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: instrumentRequest
    },
      );
    }
  


export const getPutInstrumentsIdMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putInstrumentsId>>, TError,{id: number;data: InstrumentRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putInstrumentsId>>, TError,{id: number;data: InstrumentRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putInstrumentsId>>, {id: number;data: InstrumentRequest}> = (props) => {
          const {id,data} = props ?? {};

          return  putInstrumentsId(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutInstrumentsIdMutationResult = NonNullable<Awaited<ReturnType<typeof putInstrumentsId>>>
    export type PutInstrumentsIdMutationBody = InstrumentRequest
    export type PutInstrumentsIdMutationError = Error

    export const usePutInstrumentsId = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putInstrumentsId>>, TError,{id: number;data: InstrumentRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putInstrumentsId>>,
        TError,
        {id: number;data: InstrumentRequest},
        TContext
      > => {

      const mutationOptions = getPutInstrumentsIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteInstrumentsId = (
    id: number,
 ) => {
      
      
      return strapiAxios<number>(
      {url: `/api/instruments/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteInstrumentsIdMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteInstrumentsId>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteInstrumentsId>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteInstrumentsId>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteInstrumentsId(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteInstrumentsIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteInstrumentsId>>>
    
    export type DeleteInstrumentsIdMutationError = Error

    export const useDeleteInstrumentsId = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteInstrumentsId>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteInstrumentsId>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getDeleteInstrumentsIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
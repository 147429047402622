/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * DOCUMENTATION
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Error,
  GetPeopleParams,
  PersonListResponse,
  PersonLocalizationRequest,
  PersonLocalizationResponse,
  PersonRequest,
  PersonResponse
} from '../../model'
import { strapiAxios } from '../../api/index';



export const getPeople = (
    params?: GetPeopleParams,
 signal?: AbortSignal
) => {
      
      
      return strapiAxios<PersonListResponse>(
      {url: `/api/people`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetPeopleQueryKey = (params?: GetPeopleParams,) => {
    return [`/api/people`, ...(params ? [params]: [])] as const;
    }

    
export const getGetPeopleQueryOptions = <TData = Awaited<ReturnType<typeof getPeople>>, TError = Error>(params?: GetPeopleParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPeople>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPeopleQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPeople>>> = ({ signal }) => getPeople(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPeople>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPeopleQueryResult = NonNullable<Awaited<ReturnType<typeof getPeople>>>
export type GetPeopleQueryError = Error

export const useGetPeople = <TData = Awaited<ReturnType<typeof getPeople>>, TError = Error>(
 params?: GetPeopleParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPeople>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPeopleQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postPeople = (
    personRequest: PersonRequest,
 ) => {
      
      
      return strapiAxios<PersonResponse>(
      {url: `/api/people`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: personRequest
    },
      );
    }
  


export const getPostPeopleMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postPeople>>, TError,{data: PersonRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postPeople>>, TError,{data: PersonRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postPeople>>, {data: PersonRequest}> = (props) => {
          const {data} = props ?? {};

          return  postPeople(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostPeopleMutationResult = NonNullable<Awaited<ReturnType<typeof postPeople>>>
    export type PostPeopleMutationBody = PersonRequest
    export type PostPeopleMutationError = Error

    export const usePostPeople = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postPeople>>, TError,{data: PersonRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postPeople>>,
        TError,
        {data: PersonRequest},
        TContext
      > => {

      const mutationOptions = getPostPeopleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getPeopleId = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return strapiAxios<PersonResponse>(
      {url: `/api/people/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetPeopleIdQueryKey = (id: number,) => {
    return [`/api/people/${id}`] as const;
    }

    
export const getGetPeopleIdQueryOptions = <TData = Awaited<ReturnType<typeof getPeopleId>>, TError = Error>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPeopleId>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPeopleIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPeopleId>>> = ({ signal }) => getPeopleId(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPeopleId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPeopleIdQueryResult = NonNullable<Awaited<ReturnType<typeof getPeopleId>>>
export type GetPeopleIdQueryError = Error

export const useGetPeopleId = <TData = Awaited<ReturnType<typeof getPeopleId>>, TError = Error>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPeopleId>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPeopleIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const putPeopleId = (
    id: number,
    personRequest: PersonRequest,
 ) => {
      
      
      return strapiAxios<PersonResponse>(
      {url: `/api/people/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: personRequest
    },
      );
    }
  


export const getPutPeopleIdMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putPeopleId>>, TError,{id: number;data: PersonRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putPeopleId>>, TError,{id: number;data: PersonRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putPeopleId>>, {id: number;data: PersonRequest}> = (props) => {
          const {id,data} = props ?? {};

          return  putPeopleId(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutPeopleIdMutationResult = NonNullable<Awaited<ReturnType<typeof putPeopleId>>>
    export type PutPeopleIdMutationBody = PersonRequest
    export type PutPeopleIdMutationError = Error

    export const usePutPeopleId = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putPeopleId>>, TError,{id: number;data: PersonRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putPeopleId>>,
        TError,
        {id: number;data: PersonRequest},
        TContext
      > => {

      const mutationOptions = getPutPeopleIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deletePeopleId = (
    id: number,
 ) => {
      
      
      return strapiAxios<number>(
      {url: `/api/people/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getDeletePeopleIdMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePeopleId>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deletePeopleId>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePeopleId>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deletePeopleId(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeletePeopleIdMutationResult = NonNullable<Awaited<ReturnType<typeof deletePeopleId>>>
    
    export type DeletePeopleIdMutationError = Error

    export const useDeletePeopleId = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePeopleId>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deletePeopleId>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getDeletePeopleIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const postPeopleIdLocalizations = (
    id: number,
    personLocalizationRequest: PersonLocalizationRequest,
 ) => {
      
      
      return strapiAxios<PersonLocalizationResponse>(
      {url: `/api/people/${id}/localizations`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: personLocalizationRequest
    },
      );
    }
  


export const getPostPeopleIdLocalizationsMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postPeopleIdLocalizations>>, TError,{id: number;data: PersonLocalizationRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postPeopleIdLocalizations>>, TError,{id: number;data: PersonLocalizationRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postPeopleIdLocalizations>>, {id: number;data: PersonLocalizationRequest}> = (props) => {
          const {id,data} = props ?? {};

          return  postPeopleIdLocalizations(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostPeopleIdLocalizationsMutationResult = NonNullable<Awaited<ReturnType<typeof postPeopleIdLocalizations>>>
    export type PostPeopleIdLocalizationsMutationBody = PersonLocalizationRequest
    export type PostPeopleIdLocalizationsMutationError = Error

    export const usePostPeopleIdLocalizations = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postPeopleIdLocalizations>>, TError,{id: number;data: PersonLocalizationRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postPeopleIdLocalizations>>,
        TError,
        {id: number;data: PersonLocalizationRequest},
        TContext
      > => {

      const mutationOptions = getPostPeopleIdLocalizationsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    

export const getImageUrl = (url: string | undefined) => {
    if (!url) return ""
    const baseUrl = process.env.REACT_APP_BACKEND_URL
    return baseUrl + url
}

export const getFormatDate = (date: string | undefined) => {
    if (!date) return ""


    return new Date(date).toISOString().split("T")[0].split("-").reverse().join("/")
}
import React, { useEffect, useRef } from 'react'
import './Divider.scss'

interface IDivider {
    margin: string
}

const Divider: React.FC<IDivider> = (props) => {
    const { margin } = props
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (ref.current) {
            ref.current.style.marginTop = margin
            ref.current.style.marginBottom = margin
        }
    }, [margin, ref])

    return (
        <div
            className="divider"
            ref={ref}
        ></div>
    )
}

export default Divider
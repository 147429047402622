/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * DOCUMENTATION
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ContactPageLocalizationRequest,
  ContactPageLocalizationResponse,
  ContactPageRequest,
  ContactPageResponse,
  Error,
  GetContactPageParams
} from '../../model'
import { strapiAxios } from '../../api/index';



export const getContactPage = (
    params?: GetContactPageParams,
 signal?: AbortSignal
) => {
      
      
      return strapiAxios<ContactPageResponse>(
      {url: `/api/contact-page`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetContactPageQueryKey = (params?: GetContactPageParams,) => {
    return [`/api/contact-page`, ...(params ? [params]: [])] as const;
    }

    
export const getGetContactPageQueryOptions = <TData = Awaited<ReturnType<typeof getContactPage>>, TError = Error>(params?: GetContactPageParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContactPage>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetContactPageQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContactPage>>> = ({ signal }) => getContactPage(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getContactPage>>, TError, TData> & { queryKey: QueryKey }
}

export type GetContactPageQueryResult = NonNullable<Awaited<ReturnType<typeof getContactPage>>>
export type GetContactPageQueryError = Error

export const useGetContactPage = <TData = Awaited<ReturnType<typeof getContactPage>>, TError = Error>(
 params?: GetContactPageParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContactPage>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetContactPageQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const putContactPage = (
    contactPageRequest: ContactPageRequest,
 ) => {
      
      
      return strapiAxios<ContactPageResponse>(
      {url: `/api/contact-page`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: contactPageRequest
    },
      );
    }
  


export const getPutContactPageMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putContactPage>>, TError,{data: ContactPageRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putContactPage>>, TError,{data: ContactPageRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putContactPage>>, {data: ContactPageRequest}> = (props) => {
          const {data} = props ?? {};

          return  putContactPage(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutContactPageMutationResult = NonNullable<Awaited<ReturnType<typeof putContactPage>>>
    export type PutContactPageMutationBody = ContactPageRequest
    export type PutContactPageMutationError = Error

    export const usePutContactPage = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putContactPage>>, TError,{data: ContactPageRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putContactPage>>,
        TError,
        {data: ContactPageRequest},
        TContext
      > => {

      const mutationOptions = getPutContactPageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteContactPage = (
    
 ) => {
      
      
      return strapiAxios<number>(
      {url: `/api/contact-page`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteContactPageMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteContactPage>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteContactPage>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteContactPage>>, void> = () => {
          

          return  deleteContactPage()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteContactPageMutationResult = NonNullable<Awaited<ReturnType<typeof deleteContactPage>>>
    
    export type DeleteContactPageMutationError = Error

    export const useDeleteContactPage = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteContactPage>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteContactPage>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDeleteContactPageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const postContactPageLocalizations = (
    contactPageLocalizationRequest: ContactPageLocalizationRequest,
 ) => {
      
      
      return strapiAxios<ContactPageLocalizationResponse>(
      {url: `/api/contact-page/localizations`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: contactPageLocalizationRequest
    },
      );
    }
  


export const getPostContactPageLocalizationsMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postContactPageLocalizations>>, TError,{data: ContactPageLocalizationRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postContactPageLocalizations>>, TError,{data: ContactPageLocalizationRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postContactPageLocalizations>>, {data: ContactPageLocalizationRequest}> = (props) => {
          const {data} = props ?? {};

          return  postContactPageLocalizations(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostContactPageLocalizationsMutationResult = NonNullable<Awaited<ReturnType<typeof postContactPageLocalizations>>>
    export type PostContactPageLocalizationsMutationBody = ContactPageLocalizationRequest
    export type PostContactPageLocalizationsMutationError = Error

    export const usePostContactPageLocalizations = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postContactPageLocalizations>>, TError,{data: ContactPageLocalizationRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postContactPageLocalizations>>,
        TError,
        {data: ContactPageLocalizationRequest},
        TContext
      > => {

      const mutationOptions = getPostContactPageLocalizationsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
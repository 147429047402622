import React from 'react'
import './Person.scss'
import Avatar from 'components/Avatar/Avatar'
import { PersonListResponseDataItem } from "model"
import Divider from "components/Divider/Divider"

const Person: React.FC<PersonListResponseDataItem> = (props) => {
    const { attributes } = props

    if (!attributes) return null

    const { avatar, name, description } = attributes

    return (
        <div className={"person"}>
            <Avatar {...avatar}/>
            <div className={"person__name"}>{name}</div>
            <div className={"person__description"}>{description}</div>
        </div>
    )
}

export default Person